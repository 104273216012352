/** @module number events */
import $ from 'jquery';

let $elemSec  = $('.numbers'),
    $elem     = $('.number'),
    duration  = 2000,
    $window   = $(window); // 1s


$window.scroll(function() {
  let scroll = $window.scrollTop(),
      elemSecOffset;

  $elemSec.each(function() {
    let elemSecOffset = $(this).offset().top,
        elemHeight    = $(this).height();

    if(scroll >= elemSecOffset - elemHeight*2){
      countNumber();
    }

  });
});

function countNumber(){
  $elem.each(function(){

    let minVal    = $(this).attr('data-min'),
        maxVal    = $(this).attr('data-max');

    $(this).prop('Counter', minVal).animate({
        Counter: maxVal
    },{
      duration: duration,
      easing: 'swing',
      step: function (interval) {
          $(this).text(Math.ceil(interval));
      }
    });

  });
}
