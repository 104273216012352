/** @module Modal */

import $ from 'jquery';

let $trigger       = $('.gallery-hover a'),
    $galleryModal  = $('.gallery-modal'),
    $modalSlider   = $('.modal-slider'),
    $modalNavi     = $('.modal-navi'),
    $modalClose    = $('.js-modal-close'),
    $nextArrow     = $modalNavi.find('.icon--arrow-right'),
    $prevArrow     = $modalNavi.find('.icon--arrow-left');

$modalSlider.slick({
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: $nextArrow,
  prevArrow: $prevArrow,
  fade: true,
  adaptiveHeight: true
});

$trigger.click( function(e) {
  e.preventDefault();
  let currentNumber = $(this).parents('.gallery-item').index();
  console.log(currentNumber-1);
  $galleryModal.addClass('modal-open');
  $modalSlider.slick("slickGoTo", currentNumber-1);
});

$modalClose.click( () => {
  $galleryModal.removeClass('modal-open');
});
