/** @module CookiesMonster form file */

import $ from 'jquery';

function eatCookie(e) {
    let t;
    if (document.cookie !== "") {
        let n = document.cookie.split("; ");
        for (t = 0; t < n.length; t++) {
            let r = n[t].split("=")[0];
            let i = n[t].split("=")[1];
            if (r === e) {
                return unescape(i)
            }
        }
    }
}

function create(e, t, n, r) {
    if (t === undefined) {
        t = "#005ca9"
    }
    if (n === undefined) {
        n = "#fff"
    }
    if (r === undefined) {
        r = false
    }
    let i = eatCookie("cookiePolicy");
    if (i !== "1") {
        let s = document.createElement("div");
        s.style.width = "930px";
        s.style.backgroundColor = t;
        s.style.padding = "10px 8px";
        s.style.lineHeight = "20px";
        s.style.left = "0px";
        s.style.width = "100%";
        if (r) {
            s.style.margin = "0px auto 10px auto";
            s.style.top = "0px";
            s.style.position = "relative"
        } else {
            s.style.position = "fixed";
            s.style.margin = "10px auto 0px auto";
            s.style.bottom = "0px"
        }
        s.style.zIndex = "1000";
        s.style.fontSize = "16px";
        s.style.fontFamily = '"Helvetica Neue",Helvetica,Arial,sans-serif"';
        s.style.color = n;
        let o = document.createElement("p");
        o.style.position = "relative";
        o.style.maxWidth = "1100px";
        o.style.margin = "12px auto";
        o.style.padding = "0 20px";
        o.style.fontSize = "16px";
        o.style.color = "white";
        o.innerHTML = 'W celu świadczenia usług na najwyższym poziomie w ramach naszej witryny stosujemy pliki cookies. Korzystanie z niej bez zmiany ustawień dotyczących plików cookies oznacza, że będą one zamieszczane w Państwa komputerze / telefonie / tablecie. W każdym momencie można dokonać zmian tych ustawień. Więcej szczegółów na ten temat znajduje się w naszej "<a href="' + e + '" style="text-decoration: underline; color: ' + n + '" target="_blank">Polityce Prywatności</a>". Czy zgadzają się Państwo z zainstalowaniem plików cookies?<br/>';
        let u = document.createElement("a");
        u.style.display = "block";
        u.style.float = "left";
        u.style.styleFloat = "left";
        u.style.cssFloat = "left";
        u.style.padding = "5px 15px";
        u.style.backgroundColor = n;
        u.style.color = t;
        u.style.marginTop = "10px";
        u.style.textDecoration = "none";
        u.style.textTransform = "uppercase";
        u.style.fontWeight = "600";
        u.style.fontFamily = "nimbus-sans,sans-serif";
        u.href = "#";
        u.innerHTML = "Zgadzam się";
        u.onclick = () => {
            let e = new Date;
            e.setDate(e.getDate() + 3650);
            document.cookie = "cookiePolicy=1;path=/;domain=" + window.location.host +  "; expires=" + e.toGMTString();
            document.body.removeChild(s)
        };

        let a = document.createElement("a");
        a.id = "omnomnom";
        a.style.display = "block";
        a.style.float = "left";
        a.style.styleFloat = "left";
        a.style.cssFloat = "left";
        a.style.padding = "5px 15px";
        a.style.backgroundColor = n;
        a.style.color = t;
        a.style.marginLeft = "30px";
        a.style.textDecoration = "none";
        a.style.marginTop = "10px";
        a.style.fontWeight = "600";
        a.style.textTransform = "uppercase";
        a.style.fontFamily = "nimbus-sans,sans-serif";
        a.href = e;
        a.innerHTML = "Nie zgadzam się i chcę zmienić ustawienia dotyczące cookies";
        o.appendChild(u);
        o.appendChild(a);
        if (r) {
            let f = document.createElement("br");
            f.style.clear = "both";
            o.appendChild(f)
        }
        s.appendChild(o);
        document.body.insertBefore(s, document.body.childNodes[0]);
    }
}


// RUN MODULES
if (document.cookie.indexOf('proevent_accept') < 0) {
    $('.cookies').addClass('show');
    $('.js-close-cookies').on('click', function(e) {
        e.preventDefault();
        let d = new Date();
        d.setTime(d.getTime() + (30*24*60*60*1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = "proevent_accept=1; expires=" + expires;
        $('.cookies').removeClass('show');
        return false;
    })
}
