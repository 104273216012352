import $ from 'jquery';
import Preloader from './modules/preloader';
import './modules/header';
import './modules/cookies';
import './modules/ga';
import './modules/menu';
import './modules/number';
import './modules/feedback';
import './modules/modal';
import './modules/contact';

$(document).ready(() => {
    Preloader.hide();

    $('.base .more').hide(0);
    $('.base').mouseover(function(){
      $(this).find('.more').slideDown(0);
      $(this).addClass('hover');
      $(this).find('i').addClass('white');
    });
    $('.base').mouseleave(function(){
      $(this).find('.more').slideUp(0);
      $(this).removeClass('hover');
      $(this).find('i').removeClass('white');
    });
});
