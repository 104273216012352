import $ from 'jquery';
import slick from 'slick-carousel';


let $descriptionSlider = $('.feedback-slider-description ul'),
    $fotoSlider        = $('.feedback-slider-img ul'),
    $prevArrow         = $('.feedback-slider-navi .icon--arrow-left'),
    $nextArrow         = $('.feedback-slider-navi .icon--arrow-right'),
    $currentSLide      = $('.slides-number .current'),
    $allSlides         = $('.slides-number .all'),
    totalSlides        = $('.feedback-slider-img li').length;


$descriptionSlider.slick({
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: $nextArrow,
  prevArrow: $prevArrow,
  asNavFor: $fotoSlider,
  adaptiveHeight: true
});


$fotoSlider.slick({
  dots: false,
  infinite: true,
  fade: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: $nextArrow,
  prevArrow: $prevArrow,
  asNavFor: $descriptionSlider
});


$allSlides.text(totalSlides);
$('.feedback-slider-navi .slick-arrow').click( function() {
  let currentSlider = $('.feedback-slider-img').find('.slick-active').index();
  $currentSLide.text(currentSlider+1);
});
