/** @module menu events */
import $ from 'jquery';

let $menu               =   $('.menu'),
    $nav                =   $('nav li'),
    $link               =   $nav.find('a'),
    $body               =   $('body, html'),
    $section            =   $('section'),
    $logo               =   $('.logo'),
    menuTrigger         =   $('.page-content').offset().top,
    $mobileTrigger      =   $('.menu-mobile-trigger'),
    fixed               =   false;


$mobileTrigger.click( () => {
  $menu.toggleClass('menu-open');
});


// on nav click
$nav.on('click', function(e) {
    e.preventDefault();
    $menu.removeClass('menu-open');

    let href    =   $(this).attr('data-scroll'),
        target  =   $('section[data-scroll='+ href +']');

    if (href == 'start') {
      $body.animate({ scrollTop: 0 }, 1000);
    } else if (!href) {
      return false;
    }else{
      $body.animate({
          scrollTop: $(target).offset().top
      }, 1000);
    }
});

// Run on scroll and after reload
$(document).scroll(onScroll);
onScroll();

// Chage section active on scroll
function onScroll(){
  changeMenuOnScroll();
  fixedMenuOnScroll();
}

function changeMenuOnScroll(){
  $section.each( function() {

      let top         =   window.pageYOffset,
          distance    =   $(this).offset().top,
          hash        =   $(this).attr('data-scroll');

      if($(window).scrollTop() >= distance) {
          $nav.removeClass('active');
          $nav.find('a[href="#'+ hash +'"]').parent().addClass('active');
      }
  });
}

function fixedMenuOnScroll() {
  if( $(document).scrollTop() >= menuTrigger ) {
    if( !fixed ) {
      fixed = true;
      $menu.css({'position':'fixed', 'top':'0'});
    }
  } else {
    if( fixed ) {
      fixed = false;
      $menu.css({'position':'absolute'});
    }
  }
}


// go top on logo click
$logo.click( () => {
    $body.animate({ scrollTop: 1 }, 500);
});
