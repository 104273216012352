/** @module Contact form module */
import $ from 'jquery';

let $formObject = $('#form'),
    valid;


// init contact form function
$(document).ready( () => {
  _ContactForm(
    $formObject,
    $formObject.attr('method'),
    $formObject.attr('action')
  );
});


/**
 * Create contact form funcion
 *
 * @param  {object} form       - contact form ID
 * @param  {string} formType   - form type (post/get)
 * @param  {string} formAction - form action after success send
 */
export const _ContactForm = (form,formType,formAction) => {

    let $form         =   form,
        $submit       =   $form.find('.submit'),
        $formName     =   $form.find('#formName'),
        $formMail     =   $form.find('#formMail'),
        $formPhone    =   $form.find('#formPhone'),
        $textArea     =   $form.find('#formDescription'),
        minLength     =   2;

    $submit.click((e) => {
      e.preventDefault();
      valid = true;

      if( !$formName.val() ||
          $formName.val() < minLength ||
          !validateAlphabet($formName.val()) ){

          formValid($formName);
      }
      if( !$formMail.val() ||
          $formMail.val() < minLength ||
          !validateEmail($formMail.val()) ){

          formValid($formMail);
      }
      if( !$formPhone.val() ||
          !validatePhone($formPhone.val()) ){

          formValid($formPhone);
      }
      if( !$textArea.val() ||
          $textArea.val() < minLength ){

          formValid($textArea);
      }
      if(valid){
        let _POST = $form.serializeArray();

        $.post(formAction, _POST)
        .done(function(){
          $form[0].reset();
        })
        .fail(function(){
          console.log("Form error send");
        });

      }
    });
}


/**
 * input,textarea - remove error stye from input on select
 */
$('input, textarea').focus( function() {
  $(this).removeClass('error');
});


/**
 * Function to validate form field
 * @param  {object} object to validate
 */
let formValid = (formObject) => {
  formObject.addClass('error');
  valid = false;
}


/**
 * Function to validate alphabet
 * @param  {object} object to validate
 */
let validateAlphabet = (value) => {
   let regexp = /^[a-zA-ZżółćęśąźńŻÓŁĆĘŚĄŹŃ]*$/;
   return regexp.test(value);
}


/**
 * Function to validate phone
 * @param  {object} object to validate
 */
let validatePhone = (sPhone) => {
    let filter = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/;
    if (filter.test(sPhone)) {
        return true;
    } else {
        return false;
    }
}


/**
 * Function to validate e-mail
 * @param  {object} object to validate
 */
let validateEmail = (sEmail) => {
    let filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (filter.test(sEmail)) {
        return true;
    }else {
        return false;
    }
}
