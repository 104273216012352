import $ from 'jquery';

let $content      = $('.page-content'),
    $header       = $('header'),
    range         = $content.offset().top,
    $bd           = $('body, html'),
    $scrollDown   = $('.scroll-down'),
    $firstSection = $('.page-content');

$(window).on('scroll', function () {

    let scrollTop = $(this).scrollTop(),
        offset    = -$content.offset().top;

    let calc  = 1 - (offset + scrollTop) / range-1;

    $header.css({ 'opacity': calc });
    if ( calc >= '1' ) {
      $header.css({ 'opacity': 1 });
    } else if ( calc <= '0' ) {
      $header.css({ 'opacity': 0 });
    }

});

$header.find('button').click( () => {
  $bd.animate({ scrollTop: $(document).height() }, 1000);
});

$scrollDown.click( () => {
  let targetSection = $firstSection.offset().top;
  $bd.animate({ scrollTop: targetSection }, 500);
});
